
import { defineComponent } from "vue";
import location from "../location";

export default defineComponent({
  name: "LocationDemo",
  data() {
    return {
      locationResult: {},
    };
  },
  methods: {
    getH5Location() {
      const options = {
        sessionCache: false,
        useWxSdk: false,
        useTencentMapApi: true,
        useH5Geolocation: true,
      };
      location.getLocation(options as any).then((location) => {
        this.locationResult = location as any;
      });
    },
    getTencentMapLocation() {
      const options = {
        sessionCache: false,
        useWxSdk: false,
        useTencentMapApi: false,
        useH5Geolocation: false,
      };
      location.getLocation(options as any).then((location) => {
        this.locationResult = location as any;
      });
    },
    getTencentMapIPLocation() {
      const options = {
        sessionCache: false,
        useWxSdk: false,
        useTencentMapApi: true,
        useH5Geolocation: false,
      };
      location.getLocation(options as any).then((location) => {
        this.locationResult = location as any;
      });
    },
    getWeixinSdkLocation() {
      const options = {
        sessionCache: false,
        useWxSdk: true,
        useTencentMapApi: false,
        useH5Geolocation: false,
      };
      location.getLocation(options as any).then((location) => {
        this.locationResult = location as any;
      });
    },
  },
});
