import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      class: "button",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.getH5Location && _ctx.getH5Location(...args)), ["stop"]))
    }, " 获取H5原生定位位置 "),
    _createElementVNode("button", {
      class: "button",
      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.getTencentMapLocation && _ctx.getTencentMapLocation(...args)), ["stop"]))
    }, " 获取腾讯地图定位位置 "),
    _createElementVNode("button", {
      class: "button",
      onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.getTencentMapIPLocation && _ctx.getTencentMapIPLocation(...args)), ["stop"]))
    }, " 获取腾讯地图IP定位位置 "),
    _createElementVNode("button", {
      class: "button",
      onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.getWeixinSdkLocation && _ctx.getWeixinSdkLocation(...args)), ["stop"]))
    }, " 获取微信SDK定位位置 "),
    _createElementVNode("p", null, _toDisplayString(_ctx.locationResult), 1)
  ]))
}